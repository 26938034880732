import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Layout from "../layout/Layout";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import { getUser } from "../utils/auth";
import { staticPage } from "../../config/staticPage";
import useGetAccountDetails from "../hooks/customer/useGetAccountDetails";
import { createOption } from "../utils/components";
import ChangePasswordForm from "../components/account/ChangePasswordForm";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";

const BUSINESS_TYPE_OPTIONS = [
  createOption("Online Vape Store", "Online Vape Store"),
  createOption("Online CBD Store", "Online CBD Store"),
  createOption("Brick and Mortar Vape Store", "Brick and Mortar Vape Store"),
  createOption("Brick and Mortar CBD Store", "Brick and Mortar CBD Store"),
  createOption(
    "Brick and Mortar Tobacco / Glass / Vape Store",
    "Brick and Mortar Tobacco / Glass / Vape Store"
  ),
  createOption("Convenience Store", "Convenience Store"),
  createOption("Gas Station", "Gas Station"),
  createOption("Distributor", "Distributor"),
  createOption("Cash-Carry / Jobber", "Cash-Carry / Jobber"),
];

const ABOUT_US_OPTIONS = [
  createOption("Trade Show", "Trade Show"),
  createOption(
    "Champs Atlantic City May 2023",
    "Champs Atlantic City May 2023"
  ),
  createOption(
    "Alt Pro Expo Columbia May 2023",
    "Alt Pro Expo Columbia May 2023"
  ),
  createOption("Email", "Email"),
  createOption("Google Search", "Google Search"),
  createOption("E-Juice Magazine", "E-Juice Magazine"),
  createOption("Facebook", "Facebook"),
  createOption("Instagram", "Instagram"),
  createOption("Twitter", "Twitter"),
  createOption("Midwest Goods Catalogue", "Midwest Goods Catalogue "),
  createOption("Midwest Goods Sales Team", "Midwest Goods Sales Team"),
  createOption("CBD To Store", "CBD To Store"),
  createOption("Other", "Other"),
];

function AccountSettingsPage({ location }) {
  const { details: representative } = useGetRepresentativesDetails();
  const { getCustomerSettings, updateCustomerSettings } =
    useGetAccountDetails();
  const [customerInfo, setCustomerInfo] = useState();
  const user = getUser();

  const getCustomerDetails = async () => {
    const data = await getCustomerSettings(user);
    if (Object.keys(data).length !== 0) {
      setCustomerInfo(data);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const savedValues = {
    id: customerInfo?.id,
    email: customerInfo?.email,
    first_name: customerInfo?.first_name,
    last_name: customerInfo?.last_name,
    company_name: customerInfo?.company,
    phone: customerInfo?.phone,
    ein_number: customerInfo?.ein_number,
    business_type: customerInfo?.business_type,
    website_url: customerInfo?.website_url,
    about_us: customerInfo?.about_us,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: savedValues,
  });

  useEffect(() => {
    // Use useEffect to update form values when customerInfo changes
    if (customerInfo) {
      setValue("id", customerInfo?.id);
      setValue("email", customerInfo?.email);
      setValue("first_name", customerInfo?.first_name);
      setValue("last_name", customerInfo?.last_name);
      setValue("company_name", customerInfo?.company);
      setValue("phone", customerInfo?.phone);
      setValue("ein_number", customerInfo?.ein_number || "");
      setValue("business_type", customerInfo?.business_type || "");
      setValue("website_url", customerInfo?.website_url || "");
      setValue("about_us", customerInfo?.about_us || "");
    }
  }, [customerInfo, setValue]);

  const onSubmit = async (values) => {
    const newObj = {
      email: values["email"],
      first_name: values["first_name"],
      last_name: values["last_name"],
      company: values["company_name"],
      phone: values["phone"],
      id: values["id"],
      form_fields: [
        {
          name: "Type of Business",
          value: values["business_type"],
        },
        {
          name: "How did you hear about us?",
          value: values["about_us"],
        },
        {
          name: "Your Website URL If Applicable",
          value: values["website_url"],
        },
        {
          name: "Ein # ( 9 digit Number located on IRS Letter or Tax Forms )",
          value: values["ein_number"],
        },
      ],
    };
    const finalObject = [newObj];
    const res = await updateCustomerSettings(finalObject);
    if (res) {
      getCustomerDetails();
    }
  };

  return (
    <>
      <div className="page-wrapper account-page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>

        <div className="container">
          <div className="account-content">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <div className="page-sidebar-inner">
                  <AccountPageNavigation path={"/account-settings"} />
                  {representative && (
                    <RepresentativeNavigation representative={representative} />
                  )}{" "}
                </div>
              </div>
              <div className="col page-content">
                <div className="page-content-inner">
                  <div className="page-heading-section">
                    <h1 className="page-title">Account Settings</h1>
                  </div>

                  <form
                    className="form form-mid page-block"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <input
                      id="id"
                      type="number"
                      {...register("id", {
                        required: "id is required",
                      })}
                      className={`form-input ${errors.id ? "error" : ""}`}
                      hidden
                    />
                    <div className="form-row form-row--half">
                      <div className="form-field">
                        <label className="form-label">
                          First Name
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="first_name"
                          type="text"
                          {...register("first_name", {
                            required: "First name is required",
                          })}
                          className={`form-input ${
                            errors.first_name ? "error" : ""
                          }`}
                          maxLength={80}
                        />
                        {errors.first_name && (
                          <div className="form-error-message">
                            {errors.first_name.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Last Name
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="last_name"
                          type="text"
                          {...register("last_name", {
                            required: "Last name is required",
                          })}
                          className={`form-input ${
                            errors.last_name ? "error" : ""
                          }`}
                          maxLength={80}
                        />
                        {errors.last_name && (
                          <div className="form-error-message">
                            {errors.last_name.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">Company Name</label>
                        <input
                          id="company_name"
                          type="text"
                          {...register("company_name")}
                          className={`form-input`}
                          maxLength={50}
                        />
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Phone Number
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="phone"
                          type="number"
                          {...register("phone", {
                            required: "Phone number is required",
                            minLength: {
                              value: 10,
                              message:
                                "Phone number must be at least 10 digits long.",
                            },
                            maxLength: {
                              value: 14,
                              message:
                                "Phone number should not exceed 14 digits in length.",
                            },
                          })}
                          className={`form-input ${
                            errors.phone ? "error" : ""
                          }`}
                        />
                        {errors.phone && (
                          <div className="form-error-message">
                            {errors.phone.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Email Address
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="email"
                          type="email"
                          {...register("email", {
                            required: "Email is required",
                          })}
                          className={`form-input ${
                            errors.email ? "error" : ""
                          }`}
                          disabled
                        />
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Ein # ( 9 digit Number located on IRS Letter or Tax
                          Forms ) No Dash Please
                          <small>REQUIRED</small>
                        </label>
                        <input
                          id="ein_number"
                          type="number"
                          {...register("ein_number", {
                            required: "Ein number is required",
                            minLength: {
                              value: 9,
                              message: "Ein number must be exactly 9 digits",
                            },
                            maxLength: {
                              value: 9,
                              message: "Ein number must be exactly 9 digits",
                            },
                          })}
                          className={`form-input ${
                            errors.ein_number ? "error" : ""
                          }`}
                        />
                        {errors.ein_number && (
                          <div className="form-error-message">
                            {errors.ein_number.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Type of Business
                          <small>REQUIRED</small>
                        </label>
                        <select
                          className={`form-select ${
                            errors.business_type ? "error" : ""
                          }`}
                          aria-label="Select example"
                          {...register("business_type", {
                            required: "Business type is required",
                          })}
                        >
                          <option value="">Select one</option>
                          {BUSINESS_TYPE_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.business_type && (
                          <div className="form-error-message">
                            {errors.business_type.message}
                          </div>
                        )}
                      </div>

                      <div className="form-field">
                        <label className="form-label">
                          Your Website URL If Applicable
                        </label>
                        <input
                          id="website_url"
                          type="text"
                          {...register("website_url")}
                          className={`form-input`}
                          maxLength={50}
                        />
                      </div>
                      <div className="form-field">
                        <label className="form-label">
                          How did you hear about us?
                          <small>REQUIRED</small>
                        </label>
                        <select
                          className={`form-select ${
                            errors.about_us ? "error" : ""
                          }`}
                          aria-label="Select example"
                          {...register("about_us", {
                            required: "How did you hear about us? is required",
                          })}
                        >
                          <option value="">Select one</option>
                          {ABOUT_US_OPTIONS.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.about_us && (
                          <div className="form-error-message">
                            {errors.about_us.message}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-actions text-center">
                      <button type="submit" className="button">
                        Save Setting
                      </button>
                    </div>
                  </form>

                  <ChangePasswordForm
                    id={customerInfo?.id}
                    email={customerInfo?.email}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "account-settings") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails.title}</title>
      <meta name="description" content={loginDetails.description} />
    </>
  );
};

AccountSettingsPage.Layout = Layout;
export default AccountSettingsPage;
