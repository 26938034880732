import { useState } from 'react'
import Api from '../../services/Api'
import useToastify from '../ui/useToastify';

function useGetAccountDetails() {
  const [customerInfo, setCustomerInfo] = useState()  
  const { toastMessage } = useToastify();
 
  async function getCustomerSettings(user) {
    const url = user?.customer_email
      ? `/customer/settings`
      : null
    const result = await getSettings(url, user)    
    setCustomerInfo(result)
    return result
  }

  async function updateCustomerSettings(data) {
    const url = `/customer/settings`      
    const result = await updateSettings(url, data)     
    if (result?.status !== 200) {      
      toastMessage('error', result?.response?.data?.error)
      return false
    }
    toastMessage('success', result?.data?.message)
    return true
  }

  async function updateCustomerPassword(data) {
    let res = {}
    const url = `/customer/settings/changepassword`      
    const result = await updatePassword(url, data)    
    if (result?.status !== 200) {
      if(result?.response?.status === 400) {             
        toastMessage('error', result?.response?.data?.error)
        res = {
          'falg': false,
          'message': result?.response?.data?.error
        }
        return res
      } else {
        toastMessage('error', result?.response?.data?.error)
        res = {
          'falg': false,
          'message': ''
        }
        return res 
      }               
    }
    toastMessage('success', result?.data?.message)
    res = {
      'falg': true,
      'message': ''
    }
    return res
  }
 
  return {    
    customerInfo,        
    getCustomerSettings,
    updateCustomerSettings,
    updateCustomerPassword
  }
}

async function getSettings(url, user) {
  const payload = {
    email: user?.customer_email,
  }
  const response = await Api.get(url, payload)
  if (response?.status !== 200) {    
    return {}
  }
  return response?.data
}

async function updateSettings(url, data) {  
  const response = await Api.put(url, data)
  return response  
}

async function updatePassword(url, data) {  
  const response = await Api.put(url, data)
  return response  
}

export default useGetAccountDetails
