import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    passwordRegExp,
    passwordRegMsg
} from "../../utils/form";
import useGetAccountDetails from "../../hooks/customer/useGetAccountDetails";
import { reset } from "../../features/userSlice";

function ChangePasswordForm({ id, email }) {
    const { updateCustomerPassword } = useGetAccountDetails();
    const [incorrectPass, setIncorrectPass] = useState('')

    const savedValues = {
        id: id,
        email: email,
        current_password: '',
        password: '',
        confirm_password: '',
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue
    } = useForm({
        defaultValues: savedValues,
    });

    useEffect(() => {
        // Use useEffect to update form values when customerInfo changes    
        setValue("id", id);
        setValue("email", email);
    }, [setValue, id, email]);


    const onSubmit = async (values) => {
        const newObj = {
            id: values["id"],
            email: values["email"],
            password: values["current_password"],
            new_password: values["password"]
        };
        const res = await updateCustomerPassword(newObj);
        if (res?.flag) {
            setTimeout(() => {
                reset(savedValues);
            }, 2000);
        } else {
            if (res?.message !== '') {
                setIncorrectPass(res?.message);
            } else {
                setIncorrectPass('');
            }
        }

    };

    const onChangeCurrentPassword = () => {
        setIncorrectPass('');
    };

    return (
        <>
            <div className="form-mid">
                <h4 className="page-title title-small">Change Password</h4>
            </div>
            <form
                className="form create-account-form form-mid"
                onSubmit={handleSubmit(onSubmit)}
            >
                <input
                    id="id"
                    type="number"
                    {...register("id", {
                        required: "id is required",
                    })}
                    className={`form-input ${errors.id ? "error" : ""}`}
                    hidden
                />
                <input
                    id="email"
                    type="email"
                    {...register("email", {
                        required: "Email is required",
                    })}
                    className={`form-input ${errors.email ? "error" : ""}`}
                    hidden
                />
                <div className="form-row form-row--half">
                    <div className="form-field">
                        <label className="form-label">
                            Current Password
                            <small>REQUIRED</small>
                        </label>
                        <input
                            id="current_password"
                            type="password"
                            {...register("current_password", {
                                required: "Current password is required",
                                pattern: {
                                    value: passwordRegExp,
                                    message: passwordRegMsg(),
                                },
                            })}
                            className={`form-input ${errors.current_password || incorrectPass !== '' ? "error" : ""
                                }`}
                            onChange={onChangeCurrentPassword}
                        />
                        {errors.current_password && (
                            <div className="form-error-message">
                                {errors.current_password.message}
                            </div>
                        )}
                        {incorrectPass !== '' && (
                            <div className="form-error-message">
                                {incorrectPass}
                            </div>
                        )}
                    </div>

                    <div className="form-field">
                        <label className="form-label">
                            New Password
                        </label>
                        <input
                            id="password"
                            type="password"
                            {...register("password", {
                                pattern: {
                                    value: passwordRegExp,
                                    message: passwordRegMsg(),
                                },
                            })}
                            className={`form-input ${errors.password ? "error" : ""}`}
                        />
                        {errors.password && (
                            <div className="form-error-message">
                                {errors.password.message}
                            </div>
                        )}
                    </div>

                    <div className="form-field">
                        <label className="form-label">
                            Confirm Password
                            <small>REQUIRED</small>
                        </label>
                        <input
                            id="confirm_password"
                            type="password"
                            {...register("confirm_password", {
                                validate: (value) =>
                                    value === watch("password") || "Passwords do not match",
                            })}
                            className={`form-input ${errors.confirm_password ? "error" : ""
                                }`}
                        />
                        {errors.confirm_password && (
                            <div className="form-error-message">
                                {errors.confirm_password.message}
                            </div>
                        )}
                    </div>
                </div>

                <div className="form-actions text-center">
                    <button type="submit" className="button">
                        Change Password
                    </button>
                </div>
            </form>
        </>
    );
}


export default ChangePasswordForm;
