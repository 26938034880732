// export const emailRegExp = /^[^A-Z|'|']*$/
export const emailRegExp = /^[^'"]*$/
export const zipcodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/
export const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

export function getFormError(errors, name) {
  return (errors && errors[name] && errors[name]) || ''
}

// export function emailRegMsg() {
//   return `Email should not include capital lettter and '"`
// }
export function emailRegMsg() {
  return `Email should not include single quote (') or double quote (")`
}

export function passwordRegMsg() {
  return `Password must be at least 8 characters long, and contain at least one uppercase letter, one lowercase letter, one numeric digit, and one special character`
}

export function zipcodeRegMsg() {
  return `please enter valid zip code`
}

export function requiredEmailMsg() {
  return `Please enter a valid email address`
}

export function requiredMsg(name = 'field') {
  return `Please enter a ${name}`
}

export function requiredSelectMsg(name = 'field') {
  return `Please select ${name}`
}
